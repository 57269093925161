import { useEffect } from "react";
import { getFaqs } from "../libraries/content/faqs";
import FaqItem from "./FaqItem";
import { useTranslation } from "react-i18next";

export default function FAQ({ category }) {
  const { i18n, t } = useTranslation();
  const lang = i18n.language; 


  useEffect(() => {
  
    if (!lang) {
      i18n.changeLanguage("en"); 
    }
  }, [lang, i18n]);

  const faqs = getFaqs(lang, category);

  return (
    <div
      style={{
        height: "100%",
        marginTop: "90px",
        maxWidth: "800px",
        width: "80%",
        margin: "0 auto",
        paddingTop: "150px",
        paddingBottom: "100px",
        color: "#57606f",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <span style={{ fontSize: "60px", fontWeight: 600 }}>{t("FAQTitle")}</span>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {faqs?.map && faqs.map((faq) => <FaqItem key={faq.id} faq={faq} />)}
      </div>
    </div>
  );
}
