import React from "react";
import Button from "@mui/material/Button";
import { StyledLink } from "./StyledComponents";
import { Link as RouterLink } from "react-router-dom";

function DetailsButton({ targetUrl, text }) {
  return (
    <StyledLink
      component={RouterLink}
      to={targetUrl}
      style={{ textDecoration: "none" }}
    >
      <Button
        variant="outlined" // Use outlined variant to start with a border
        sx={{
          marginTop: 3,
          padding: 1.3,
          letterSpacing: 1.3,
          fontSize: 16,
          fontWeight: 400, // Not bold initially
          borderRadius: 0, // Fully squared button
          width: "70%",
          textTransform: "uppercase", // Capitalize text
          fontFamily: "NotoSans, sans-serif",
          lineHeight: 1.2, // Ensures line spacing is minimal
          color: "#ffffff", // Light blue text color
          backgroundColor: "transparent", // Transparent background initially
          border: "2px solid #ffffff", // White border initially
          overflow: "hidden",
          position: "relative",
          zIndex: 1,
          transition: "color 0.4s ease, border-color 0.4s ease", // Smooth text and border color transition

          "&::before": {
            content: '""',
            position: "absolute",
            bottom: 0, // Start from bottom
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#013461", // Darker blue hover color
            transform: "translateY(100%)", // Start below the button
            transition: "transform 0.6s ease", // Smooth sliding animation
            zIndex: -1, // Place the background behind the text
          },
          "&:hover::before": {
            transform: "translateY(0)", // Slide the background from bottom to top
          },
          "&:hover": {
            color: "#ffffff", // Text turns white on hover
            borderColor: "#2a6cb7", // Border turns blue on hover
            fontWeight: 400, // Make the text bold on hover
          },
        }}
      >
        {text}
      </Button>
    </StyledLink>
  );
}

export default DetailsButton;
