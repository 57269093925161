import React from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import "../../assets/App.css";
import { Typography } from "@mui/material";

export default function PhotoAndText({
  photo,
  photoTitle,
  photoAlt,
  texta,
  textb,
  textc,
  language,
}) {
  console.log(language);

  return (
    <Stack spacing={2} direction="column" alignItems="center">
      <img
        src={require(`../../assets/${photo}.PNG`)}
        alt={photoAlt}
        title={photoTitle}
        className="home-pic"
        style={{
          width: "65%", 
          maxWidth: 250, 
          borderRadius: "20px",
          marginBottom: 25,
          objectFit: "cover", // Maintain aspect ratio while covering the area
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          textAlign: "center",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          variant="h3"
          component="h3"
          sx={{
            maxWidth: 500,
            fontSize: { xs: 14, sm: 16 }, // Responsive font size
            fontFamily:
              language === "el" ? "NotoSans SemiBold" : "Montserrat SemiBold",
            letterSpacing: 1,
            color: "#2B2B2B",
            textAlign: "center",
          }}
        >
          {texta}
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: 400,
          fontSize: { xs: 14, sm: 16 }, // Responsive font size
          color: "#666666",
          fontFamily: "NotoSans Regular",
          textAlign: "center",
        }}
      >
        {textb}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          textAlign: "center",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{
            maxWidth: 400,
            fontSize: { xs: 14, sm: 16 }, // Responsive font size
            color: "#666666",
            fontFamily: "NotoSans Regular",
            textAlign: "center",
          }}
        >
          {textc}
        </Typography>
      </Box>
    </Stack>
  );
}
